import React, { useState, useEffect } from "react";
import "./AttendeeDetails.css";
import "../OrderHistory/OrderList.css";
import { BANNER_CONTENT, SELECTED_EVENT_CATEGORY_DATA } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as MaskView } from "../../assets/svgs/MaskView.svg";
import { ReactComponent as EditFilled } from "../../assets/svgs/EditFilled.svg";
import { ReactComponent as UserFilled } from "../../assets/svgs/UserFilled.svg";
import { ReactComponent as NoteFilled } from "../../assets/svgs/NoteFilled.svg";
import { ReactComponent as ClockFilled } from "../../assets/svgs/ClockFilledWhite.svg";
import { ReactComponent as TicketFilled } from "../../assets/svgs/TicketFilled.svg";
import { ReactComponent as SuccessFilled } from "../../assets/svgs/SuccessFilledWhite.svg";
import { ReactComponent as ScannerFilled } from "../../assets/svgs/ScannerFilled.svg";
import { ReactComponent as CalendarFilled } from "../../assets/svgs/CalendarFilled.svg";
import { ReactComponent as CloseFilled } from "../../assets/svgs/CloseFilledWhite.svg";
import { ReactComponent as MinusFilled } from "../../assets/svgs/MinusFilled.svg";
import { ReactComponent as VerifiedWhiteFilled } from "../../assets/svgs/VerifiedWhiteFilled.svg";
import { ReactComponent as MailFilled } from "../../assets/svgs/MailFilled.svg";
import { ReactComponent as SmartDigitalIcon } from "../../assets/svgs/SmartDigitalIcon.svg";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import Tags from "../../Component/Common/Tags/Tags";
import Text from "../../Component/Common/Text";
import Footer from "../../Component/FooterNew/FooterNew";
import SideBar from "../../Component/SideBar/SideBar";
import MenuLink from "../../Component/Common/MenuLink/MenuLink";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import CentralModalSuccess from "../../Component/Common/CentralModals/CentralModalSuccess";
import CentralModalOptions from "../../Component/Common/CentralModals/CentralModalOptions";
import CentralModalAvatar from "../../Component/Common/CentralModals/CentralModalAvatar";
import { setUserEntryStatus, getAttendeeDetails } from "../../services/api";
import { set } from "lodash";

function AttendeeDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { state } = location;
  let ticketId = state?.ticketId;
  const parts = window.location.pathname.split("/");
  let eventId = parts[parts.indexOf("EventDetails") + 1];
  if (!ticketId) {
    const parts = window.location.pathname.split("/");
    ticketId = parseInt(parts[parts.length - 1], 10);
  }
  const [event, setEvent] = useState(state?.event || false);
  const [attendeeItem, setAttendeeItem] = useState(false);
  const [attendeeName, setAttendeeName] = useState("");

  const [selectedAtteendeeOptionIndex, setSelectedAtteendeeOptionIndex] =
    useState(0);
  const [selectedEventOptionIndex, setSelectedEventOptionIndex] = useState(0);
  const [isCheckInModalVisible, setIsCheckInModalVisible] = useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [isAvatarModalVisible, setIsAvatarModalVisible] = useState(false);
  const [entryStatus, setEntryStatus] = useState("");

  const [eventOptions, setEventOptions] = useState([
    {
      name: "Personal details",
      id: 2,
      isSelected: false,
    },
    { name: "Ticket details", id: 3, isSelected: false },
    { name: "Booking details", id: 4, isSelected: false },
    { name: "Activity history", id: 5, isSelected: false },
  ]);

  const eventSectionOptions = [
    { name: "Overview", id: 1 },
    { name: "Event details", id: 2 },
    { name: "Venue", id: 3 },
    { name: "Requirements", id: 4 },
    { name: "Fees", id: 5 },
    { name: "Tickets", id: 6 },
    { name: "Add Ons", id: 7 },
    { name: "Guest list", id: 8 },
  ];

  const [personalDetailsOptions, setPersonalDetailsOptions] = useState([
    {
      label: "Age",
      subText: "Over 18",
      icon: <CalendarFilled />,
    },
    {
      label: "User ID",
      subText: "XXXXXXXX",
      icon: <UserFilled />,
    },
  ]);

  const [ticketDetailsOptions, setTicketDetailsOptions] = useState([
    {
      label: "Ticket ID",
      subText: "XXXXXXXX",
      icon: <NoteFilled />,
    },
    {
      label: "Ticket type",
      subText: "1 x VIP Weekend Camping",
      icon: <TicketFilled />,
    },
    {
      label: "Latest ticket scan",
      subText: "17:56",
      icon: <ScannerFilled />,
    },
  ]);

  const [bookingDetailsOptions, setBookingDetailsOptions] = useState([
    {
      label: "Booking name",
      subText: "",
      icon: <UserFilled />,
    },
    {
      label: "Booking email address",
      subText: "",
      icon: <MailFilled />,
    },
    {
      label: "Booking contact number",
      subText: "",
      icon: <SmartDigitalIcon />,
    },
    
  ]);

  const [activityDetailsOptions, setActivityDetailsOptions] = useState([
    {
      label: "Checked out",
      subText: "21:08",
      icon: <ClockFilled />,
    },
    {
      label: "Approved",
      subText: "17:56",
      icon: <SuccessFilled />,
      label2: "by Security Guard X",
    },
  ]);

  useEffect(() => {
    getAttendeeDetailsRequest();
  }, []);

  useEffect(() => {
    if (attendeeItem) {
      setAttendeeName(
        attendeeItem?.ticket?.assignmentStatus === "Assigned"
          ? (attendeeItem?.firstName
              ? attendeeItem?.firstName
              : attendeeItem?.purchaserUser?.firstName
              ? attendeeItem?.purchaserUser?.firstName
              : attendeeItem?.ticket?.ticketId) +
              " " +
              (attendeeItem?.lastName
                ? attendeeItem?.lastName
                : attendeeItem?.purchaserUser?.lastName
                ? attendeeItem?.purchaserUser?.lastName
                : "")
          : attendeeItem?.ticket?.ticketId
          ? `${attendeeItem?.ticket?.ticketId}` +
            (attendeeItem?.purchaserUser?.fullName
              ? ` (${attendeeItem?.purchaserUser?.fullName})`
              : "")
          : ""
      );

      setEntryStatus(attendeeItem?.entryStatus);
      let tempPersonalDetailsOptions = [...personalDetailsOptions];
      tempPersonalDetailsOptions[0].subText = attendeeItem?.ageDisplay;

      tempPersonalDetailsOptions[1].subText = attendeeItem?.id
        ? attendeeItem?.id
        : attendeeItem?.userId;
      setPersonalDetailsOptions(tempPersonalDetailsOptions);

      let tempTicketDetailsOptions = [...ticketDetailsOptions];
      tempTicketDetailsOptions[0].subText = attendeeItem.ticket?.ticketId;
      tempTicketDetailsOptions[1].subText = attendeeItem?.ticket?.type;
      tempTicketDetailsOptions[2].subText = attendeeItem?.ticketEntryHistory[
        attendeeItem?.ticketEntryHistory?.length - 1
      ]?.dateCreated
        ? String(
            formattedTime(
              new Date(
                String(
                  attendeeItem?.ticketEntryHistory[
                    attendeeItem?.ticketEntryHistory.length - 1
                  ]?.dateCreated
                )
              )
            )
          ) || "Not entered"
        : "Not entered";
      setTicketDetailsOptions(tempTicketDetailsOptions);

      let tempBookingDetailsOptions = [...bookingDetailsOptions];
      tempBookingDetailsOptions[0].subText = attendeeItem?.fullName
        ? attendeeItem?.fullName
        : attendeeItem?.purchaserUser?.fullName
        ? attendeeItem?.purchaserUser?.fullName
        : "Not available";
      tempBookingDetailsOptions[1].subText = attendeeItem?.emailAddress
        ? attendeeItem?.emailAddress
        : attendeeItem?.purchaserUser?.emailAddress
        ? attendeeItem?.purchaserUser?.emailAddress
        : "Not available";


        tempBookingDetailsOptions[2].subText = attendeeItem?.mobileNumber
        ? `${attendeeItem?.mobileNumber?.countryCode} ${attendeeItem?.mobileNumber?.number}`
        : attendeeItem?.purchaserUser?.mobileNumber
        ? `${attendeeItem?.purchaserUser?.mobileNumber?.countryCode} ${attendeeItem?.purchaserUser?.mobileNumber?.number}`
        : "Not available";
      setBookingDetailsOptions(tempBookingDetailsOptions);

      let tempActivityDetailsOptions = [];
      for (let i = 0; i < attendeeItem?.ticketEntryHistory.length; i++) {
        tempActivityDetailsOptions.push({
          label: attendeeItem?.ticketEntryHistory[i]?.entryStatus,
          subText:
            String(
              formattedTime(
                new Date(
                  String(attendeeItem?.ticketEntryHistory[i]?.dateCreated)
                )
              )
            ) || "Not entered",
          icon:
            attendeeItem?.ticketEntryHistory[i]?.entryStatus === "Admitted" ? (
              <SuccessFilled width={24} height={24} />
            ) : attendeeItem?.ticketEntryHistory[i]?.entryStatus?.includes(
                "Rejected"
              ) === true ? (
              <CloseFilled fill="var(--red)" width={24} height={24} />
            ) : attendeeItem?.ticketEntryHistory[i]?.entryStatus ===
              "CheckedOut" ? (
              <ClockFilled width={24} height={24} />
            ) : (
              <MinusFilled width={24} height={24} />
            ),
          label2: attendeeItem?.ticketEntryHistory[i]?.actionedBy,
        });
      }
      setActivityDetailsOptions(tempActivityDetailsOptions);
    }
  }, [attendeeItem]);

  const getAttendeeDetailsRequest = async () => {
    const params = {
      ticketId: ticketId,
      eventId: eventId,
    };
    const result = await getAttendeeDetails(params);
    if (result?.status) {
      event === false && setEvent(result?.response?.eventDetails);
      setAttendeeItem(result?.response?.attendee);
    } else {
    }
  };

  const setUserEntryStatusRequest = async (entryStatus) => {
    const result = await setUserEntryStatus({
      eventId: eventId,
      userId: attendeeItem?.userId,
      entryStatus: entryStatus,
      ticketId: ticketId
    });
    if (result.status) {
      setEntryStatus(entryStatus);
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text:
            attendeeName +
            (entryStatus === "CheckedIn"
              ? ` has been approved.`
              : entryStatus === "RejectedOther"
              ? ` has been rejected.`
              : entryStatus === "CheckedOut"
              ? ` has been checked out.`
              : ` has been not entered.`),
          headingText: "",
          showBanner: true,
          type: "Success",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    } else {
    }
  };

  const segmentBtnPressedAtteendeeDetails = (index) => {
    if (index != selectedAtteendeeOptionIndex) {
      setSelectedAtteendeeOptionIndex(index);
    }
  };

  const segmentBtnPressedEvent = (index) => {
    setSelectedEventOptionIndex(index);
    dispatch({
      type: SELECTED_EVENT_CATEGORY_DATA,
      payload: index,
    });
    navigate("/EventDetails/" + eventId);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 400);
  };

  const formattedTime = (d) =>
    `${(d.getHours() < 10 ? "0" : "") + d.getHours()}:${
      (d.getMinutes() < 10 ? "0" : "") + d.getMinutes()
    }`;

  const renderAttendeeDetails = ({ item, index }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1 / 2,
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <HoverBGButton
              backgroundHover={
                item.label === "Admitted"
                  ? "var(--hoverStateGreen)"
                  : item.label?.includes("Rejected") === true
                  ? "var(--hoverRed)"
                  : undefined
              }
              icon={item.icon}
              size={48}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 16,
              }}
            >
              <Text variant={"r300"}>{item.label}</Text>
              <Text marginTop={8} variant={"m400"}>
                {item.subText || "-"}
              </Text>
            </div>
          </div>
          <Text
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--grey"
            )}
            variant={"r300"}
          >
            {item?.label2 ? "by " + item?.label2 : ""}
          </Text>
        </div>
        {(selectedAtteendeeOptionIndex === 0 &&
          index !== personalDetailsOptions.length - 1) ||
        (selectedAtteendeeOptionIndex === 1 &&
          index !== ticketDetailsOptions.length - 1) ||
        (selectedAtteendeeOptionIndex === 2 &&
          index !== bookingDetailsOptions.length - 1) ||
        (selectedAtteendeeOptionIndex === 3 &&
          index !== activityDetailsOptions.length - 1) ? (
          <div
            style={{
              marginLeft: -44,
              marginRight: -64,
              height: 1,
              backgroundColor: getComputedStyle(
                document.documentElement
              ).getPropertyValue("--lightGrey"),
            }}
          />
        ) : null}
      </div>
    );
  };

  function ImageItem({ imageUrl }) {
    const [isValid, setIsValid] = useState(true);

    const handleImageError = () => {
      setIsValid(false);
    };

    return (
      <div>
        {isValid ? (
          <img
            onError={handleImageError}
            onClick={() => setIsAvatarModalVisible(true)}
            style={{ borderRadius: 96, cursor: "pointer" }}
            className="attendee-details-avatar"
            content="center"
            alt=""
            src={imageUrl}
          />
        ) : (
          <div
            className="attendee-details-avatar"
            style={{
              borderRadius: 96,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 1px 3px 3px rgba(0, 0, 0, 0.05)",
            }}
          >
            <UserFilled height={96} width={96} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="page-wrapper-attendee-details">
      <CentralModalAvatar
        show={isAvatarModalVisible}
        onClose={() => setIsAvatarModalVisible(false)}
        img={process.env.REACT_APP_API_URL + attendeeItem?.imageUri}
      />
      <CentralModalSuccess
        icon={<ClockFilled height={24} width={24} />}
        title={"Confirm check in"}
        description={"Are you sure you want to check in this attendee?"}
        shouldShowButtons={true}
        button1Title={"No, cancel"}
        button2Title={"Yes, check in"}
        onButton1Clicked={() => {
          setIsCheckInModalVisible(false);
        }}
        onButton2Clicked={() => {
          setIsCheckInModalVisible(false);
          setUserEntryStatusRequest("CheckedIn");
        }}
        show={isCheckInModalVisible}
        onClose={() => setIsCheckInModalVisible(false)}
      />
      <CentralModalOptions
        icon={<EditFilled height={24} width={24} />}
        title={"Modify status"}
        description={"Change the active status of an attendee for this event"}
        shouldShowButtons={true}
        button1Title={"Cancel"}
        button2Title={"Submit"}
        selectedOptionsIndex={
          entryStatus === "CheckedIn"
            ? 0
            : entryStatus?.includes("Rejected") === true
            ? 1
            : entryStatus === "CheckedOut"
            ? 2
            : entryStatus === "NotPresent"
            ? 3
            : null
        }
        onButton1Clicked={() => {
          setIsStatusModalVisible(false);
        }}
        onButton2Clicked={(value) => {
          setIsStatusModalVisible(false);
          setUserEntryStatusRequest(
            value === 0
              ? "CheckedIn"
              : value === 1
              ? "RejectedOther"
              : value === 2
              ? "CheckedOut"
              : "NotPresent"
          );
        }}
        show={isStatusModalVisible}
        onClose={() => setIsStatusModalVisible(false)}
      />
      <MaskView
        style={{ top: 0, left: 0, position: "absolute", zIndex: -33 }}
      />

      <div className="main-body-content">
        <div id="sidebar" className="home-header-sideNav">
          <SideBar
            onChangeOption={(index) =>
              navigate("/Home", {
                state: { selectedIndex: index },
              })
            }
            createEventClicked={() => navigate("/CreateEvent")}
            onGoToEventClicked={(eventId) =>
              navigate(`/EventDetails/${eventId}`, {
                state: {
                  eventId: eventId,
                },
              })
            }
          />
        </div>

        <div className="rht-body-inner-content">
          <div className="order-content-div listing-top-headerPagination">
            <MenuLink
              showAnyButton={true}
              // btn2Label={"Cancel event"}
              labelArray={[
                { label: "Home", id: 1 },
                { label: event?.name, id: 2 },
              ]}
              withCog={false}
              onMenuLinkPress={(ind) =>
                ind === 0
                  ? navigate("/Home")
                  : ind === 1
                  ? navigate("/EventDetails/" + eventId)
                  : null
              }
            />
            <div className="event-details-top-after-menulink-container">
              <Text variant={"m600"}>{event?.name}</Text>

              <div className="event-details-event-scroll-container">
                <div className="event-detailsTop-btns-inner">
                  {eventSectionOptions.map((items, ind) => {
                    return (
                      <div
                        className="btnBox"
                        key={ind}
                        style={{ marginRight: 8 }}
                      >
                        <Tags
                          disabled={false}
                          paddingVertical={8}
                          label={items.name}
                          selected={
                            ind == selectedEventOptionIndex ? true : false
                          }
                          onChangeSelection={() => segmentBtnPressedEvent(ind)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="listingPagination-block">
              <MenuLink
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderBottomColor: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--lightGrey"),
                  borderBottomWidth: "1px !important",
                  boxShadow: "0.3px 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.05)",
                  marginBottom: 0,
                }}
                labelArray={[
                  { label: "Overview", id: 1 },
                  { label: "Event entry list", id: 2 },
                  { label: attendeeName, id: 2 },
                ]}
                withCog={false}
                onMenuLinkPress={(ind) =>
                  ind === 0
                    ? navigate("/EventDetails/" + eventId)
                    : ind === 1
                    ? navigate(`/EventDetails/${eventId}/AttendeeList`, {
                        state: {
                          eventId: eventId,
                          event: event,
                        },
                      })
                    : null
                }
              />
            </div>

            <div className="attendee-details-parent-content">
              <div
                className="proLeftBox"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1 / 2,
                  alignItems: "center",
                  marginRight: 48,
                }}
              >
                <div className="avatarBox">
                  <ImageItem
                    imageUrl={
                      process.env.REACT_APP_API_URL + attendeeItem?.imageUri
                    }
                  />
                  <div
                    className="statusIconCont"
                    style={{ marginLeft: -43, marginTop: 93, zIndex: 44 }}
                  >
                    {entryStatus === "CheckedIn" ? (
                      <SuccessFilled
                        fill={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--green")}
                        width={56}
                        height={56}
                      />
                    ) : entryStatus === "NotPresent" ? (
                      <div style={{ height: 56, width: 56 }} />
                    ) : entryStatus === "CheckedOut" ? (
                      <ClockFilled
                        fill={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        width={56}
                        height={56}
                      />
                    ) : entryStatus?.includes("Rejected") ? (
                      <CloseFilled
                        fill={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--red")}
                        width={56}
                        height={56}
                      />
                    ) : (
                      <div style={{ height: 56, width: 56 }} />
                    )}
                  </div>
                </div>

                <div
                  className="rightCont"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: '48px !important',
                    width: "100%",
                  }}
                >
                  <Text textWrap={"noWrap"} width={"100%"} variant={"m600"}>
                    {attendeeName || ""}
                  </Text>

                  <div
                    className="verifiedCont"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 16,
                    }}
                  >
                    <Text
                      color={
                        attendeeItem?.kycStatus === "Verified"
                          ? "var(--midBlue)"
                          : "var(--orange)"
                      }
                      marginRight={12}
                      variant={"m400"}
                    >
                      {attendeeItem?.kycStatus === "Verified"
                        ? "Verified"
                        : "Not verified"}
                    </Text>
                    {attendeeItem?.kycStatus === "Verified" ? (
                      <VerifiedWhiteFilled />
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className="rightBtnCont"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {entryStatus === "CheckedOut" && (
                  <ButtonPrimary
                    width={335}
                    label={"Check in"}
                    onPress={() => setIsCheckInModalVisible(true)}
                    // style={{ marginBottom: 16 }}
                  />
                )}
                <ButtonPrimary
                  showLeftIcon={false}
                  width={335}
                  icon={<EditFilled />}
                  isSecondary={true}
                  label={
                    entryStatus === "NotPresent"
                      ? "Set entry status"
                      : "Modify status"
                  }
                  onPress={() => setIsStatusModalVisible(true)}
                />
              </div>
            </div>

            <div className="attendee-details-scroll-container">
              {eventOptions.map((items, ind) => {
                return (
                  <div
                    className="detailBtnBox"
                    key={ind}
                    style={{ marginRight: 12 }}
                  >
                    <Tags
                      paddingVertical={8}
                      label={items.name}
                      selected={
                        ind == selectedAtteendeeOptionIndex ? true : false
                      }
                      onChangeSelection={() =>
                        segmentBtnPressedAtteendeeDetails(ind)
                      }
                    />
                  </div>
                );
              })}
            </div>

            {selectedAtteendeeOptionIndex === 0 && (
              <div className="attendee-personal-details-parent-content">
                {personalDetailsOptions.map((item, index) => {
                  return renderAttendeeDetails({ item, index });
                })}
              </div>
            )}

            {selectedAtteendeeOptionIndex === 1 && (
              <div className="attendee-personal-details-parent-content">
                {ticketDetailsOptions.map((item, index) => {
                  return renderAttendeeDetails({ item, index });
                })}
              </div>
            )}

            {selectedAtteendeeOptionIndex === 2 && (
              <div className="attendee-personal-details-parent-content">
                {bookingDetailsOptions.map((item, index) => {
                  return renderAttendeeDetails({ item, index });
                })}
              </div>
            )}

            {selectedAtteendeeOptionIndex === 3 && (
              <div className="attendee-personal-details-parent-content">
                {activityDetailsOptions.map((item, index) => {
                  return renderAttendeeDetails({ item, index });
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default AttendeeDetails;
